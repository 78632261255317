import React, { useState } from "react";
import { StyleSheet, View, Text, Platform } from "react-native";
import { Title14, Title16, Title22 } from "@stylesheets";
import { Colors, Spacing } from "@constants";
import { ArticleBaking, IPosMenuElement, IProductTransparencyPreferences, OrderItem } from "@foodi/core";
import { FastImage, ModalTemplate, ProductQuantity, Tag, IImportationTypes, IMPORTATION_TYPES } from "@atomic";
import { IOfferItem, IProduct, OfferViewModel } from "@modules";
import { CloseBlack } from "@assets";
import { I18n } from "react-redux-i18n";
import { useDevices } from "@hooks";
import {
  Action,
  AddActionText,
  IconButton,
  Row,
  Section,
  Label,
} from "./ProductItemModal.style";
import { useSelector } from "react-redux";
import { GlobalState } from "@redux";
import { getLabelArticleBaking } from "@utils";
import { allergensMapper } from "@utils/allergensMapper";
import { Icon } from "@atomic/atoms/Icon";
import { capitalize, trimEnd, isEmpty } from 'lodash';
import { certificationsMapper } from "@utils/certificationsMapper";
import { ScrollView } from "react-native-gesture-handler";
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';

interface IProps {
  item: IOfferItem;
  selectedOfferId?: string;
  forwardTestID?: string;
  updateOrders?: Function;
  sameProductPerOrder?: number;
  isOverProductsLimit?: boolean;
  quantityOverall?: number;
  isOpen?: boolean;
  menuType?: IImportationTypes;
  productTransparencyPreferences: IProductTransparencyPreferences | null;
  handleClose?: () => void;
}

const filterCertifications = (certifications: string[]) => {

  if (certifications.includes('INFO_NOT_DISCLOSED') || certifications.includes('INFO_INCOMPLETE')) {
    return [];
  }

  return certifications;
}

interface AllergenItemProps {
  allergen: string;
  isLastItem: boolean;
}

interface AllergensSectionProps {
  allergensNotDiscolosed: boolean;
  filteredAllergens?: string[];
  allergensIntoIncomplete?: boolean;
}



interface AlertNoticeProps {
  visible: boolean;
  style?: any;
  type: 'infoIncomplete' | 'infoNotDisclosed';
}

export const AlertNotice: React.FC<AlertNoticeProps> = ({ visible, style, type }) => {
  if (!visible) return null;

  const getAlertContent = () => {
    switch (type) {
      case 'infoIncomplete':
        return {
          title: I18n.t("restaurantDetail.product.allergensList.infoIncomplete.title"),
          description: I18n.t("restaurantDetail.product.allergensList.infoIncomplete.description")
        };
      case 'infoNotDisclosed':
        return {
          title: I18n.t("restaurantDetail.product.allergensList.infoNotDisclosed.title"),
          description: I18n.t("restaurantDetail.product.allergensList.infoNotDisclosed.description")
        };
      default:
        return {
          title: '',
          description: ''
        };
    }
  };

  const alertContent = getAlertContent();

  return (
    <View style={[styles.alertContainer, style]}>
      <View style={styles.iconContainer}>
        <Text style={styles.warningSymbol}>!</Text>
      </View>
      <View style={styles.textContainer}>
        <Text style={styles.alertTitle}>
          {alertContent.title}
        </Text>
        <Text style={styles.alertText}>
          {alertContent.description}
        </Text>
      </View>
    </View>
  );
};

// Extracted AllergenItem component with TypeScript
const AllergenItem: React.FC<AllergenItemProps> = ({ allergen, isLastItem }) => {
  const { ALLERGEN_FREE, INFO_INCOMPLETE } = allergensMapper;
  const allergenName = allergensMapper[allergen];

  // Handle unknown allergens
  if (!allergenName) {
    return <>{`${allergen}${isLastItem ? "" : ", "}`}</>;
  }

  // Don't render INFO_INCOMPLETE allergen type
  if (allergenName === INFO_INCOMPLETE) {
    return null;
  }

  return (
    <View style={styles.allergenDetails}>
      {![ALLERGEN_FREE].includes(allergenName) && (
        <Icon
          name={allergenName as any}
          size={16}
          style={{ marginRight: Spacing.XS }}
        />
      )}
      <Title16>
        {I18n.t(`restaurantDetail.product.allergensList.${allergenName}`)}
      </Title16>
    </View>
  );
};

// Main component with TypeScript
const AllergensSection: React.FC<AllergensSectionProps> = ({
  allergensNotDiscolosed,
  filteredAllergens = [],
  allergensIntoIncomplete,
}) => {
  return (
    <Section>
      <Title22 textAlign="left" style={styles.title}>
        {I18n.t("restaurantDetail.product.allergens")}
      </Title22>

      {allergensNotDiscolosed ? (
        <AlertNotice
          visible={true}
          type="infoNotDisclosed"
          style={{ marginVertical: "15px" }}
        />
      ) : (
        <View style={styles.allergensDetails}>
          {filteredAllergens.map((allergen, index, array) => (
            <AllergenItem
              key={`allergen-${index}`}
              allergen={allergen}
              isLastItem={index === array.length - 1}
            />
          ))}
        </View>
      )}
      {allergensIntoIncomplete && (
        <AlertNotice
          visible={true}
          type="infoIncomplete"
          style={{ marginVertical: "15px" }}
        />
      )}
    </Section>
  );
};


export const ProductItemModal: React.FC<IProps> = React.memo(
  ({
    item,
    quantityOverall,
    isOpen,
    menuType,
    productTransparencyPreferences,
    handleClose,
    isOverProductsLimit,
    forwardTestID,
    sameProductPerOrder,
    selectedOfferId,
    updateOrders
  }) => {
    const {
      id,
      inheritedLabel,
      inheritedImage,
      inheritedPrice,
      inheritedDescription,
      inheritedFamily,
      baking,
      container,
    } = item;
    const isMenu = menuType !== undefined;

    const brandTheme = useSelector(
      (state: GlobalState) => state.brandTheme.brandTheme
    );

    const offer = useSelector(
        (state: GlobalState) => state.offers.activeOrderableOffer
    );

    const [
      chosenBaking,
      setChosenBaking,
    ] = React.useState<ArticleBaking | null>(null);
    const menuElements = offer?.menus ? offer.menus[0]?.elements : null;
    const itemMenu : any  = isMenu ? item :  menuElements ? menuElements.find(
        (element) => element?.idElement === item?.localArticle?.article?.idElement
    ) : {} as IPosMenuElement;
    const allergens = itemMenu?.allergens;
    const certifications = itemMenu?.certifications || [];
    const products: IProduct[] = itemMenu?.products;
    const [offerVM] = useState(new OfferViewModel());
    const [isMobile] = useDevices();
    const [quantityWithBaking, setQuantityWithBaking] = React.useState<number>(
      1
    );

    const orderItems = useSelector((state: GlobalState) => {
      return state.offers.orderItems.filter((item) => {
        return item.id === id;
      });
    });

    const handleChosenBaking = (type: ArticleBaking) => {
      if (chosenBaking === type) {
        setChosenBaking(null);
      } else {
        setChosenBaking(type);
      }
    };

    const onAddToCartWithBaking = () => {
      let quantity = quantityWithBaking;
      let index = orderItems.findIndex(
        (item) => item.chosenBaking === chosenBaking
      );
      if (index === -1) {
        quantity = quantityWithBaking;
      } else {
        quantity += orderItems[index].quantity;
      }
      const orderItem: OrderItem = {
        __typename: "OrderItem",
        id: id,
        quantity: quantity,
        chosenBaking: chosenBaking,
        labelWhenAdded: inheritedLabel,
        priceWhenAdded: {
          amount: inheritedPrice.amount,
          currency: inheritedPrice.currency,
        },
        containerLabelWhenAdded: container?.label ? container.label : null,
        containerPriceWhenAdded: {
          amount: container?.price?.amount ? container.price.amount : '',
          currency: inheritedPrice.currency,
        },
        offerItem: {
          __typename: "OfferItem",
          id: selectedOfferId || "id",
          inheritedFamily: inheritedFamily,
        },
      };
      updateOrders?.(orderItem);
      handleClose?.();
    };

    const onControlQuantityWithBaking = (quantity: number) => {
      setQuantityWithBaking(quantity);
    };

    React.useEffect(() => {
      return () => {
        if (!isOpen) {
          setQuantityWithBaking(1);
          setChosenBaking(null);
        }
      };
    }, [isOpen]);

    const renderLabels = () => {
      const certificationsNotDiscolosed = certifications?.includes('INFO_NOT_DISCLOSED') || certifications?.includes('INFO_INCOMPLETE');
      // IF we hide ingredients we must show Labels in the Label sections
      if (certificationsNotDiscolosed || certifications?.length === 0 || products?.length && products.length > 1) {
        return null;
      }

      return (
        <Section>
          <Title22 textAlign="left" style={styles.title}>
            {I18n.t("restaurantDetail.product.label")}
          </Title22>
          <Row wrap>
            {certifications?.map((label: any) => {
              const labelName = (certificationsMapper as any)[label];

              return <Label>
                <Title14>{!!labelName ? I18n.t(`restaurantDetail.product.certifications.${labelName}`) : labelName}</Title14>
              </Label>
            }
            )}
          </Row>
        </Section>
      );
    }

    const renderIngredients = () => {
      if (!products) {
        return null;
      }

      const filteredProducts = products.filter(
        ({ idRecipe: mainIdRecipe, main, certifications = [] }) => {
          if (menuType == IMPORTATION_TYPES.WINAPRO) {
            return (
              main &&
              (showIngredients ||
                (showLabels &&
                  filterCertifications(certifications as string[]).length >
                    0) ||
                !!products.some(
                  ({ idRecipe, main, certifications }) =>
                    !main &&
                    !!idRecipe &&
                    !!mainIdRecipe &&
                    idRecipe.includes(mainIdRecipe) &&
                    showLabels &&
                    filterCertifications(certifications as string[]).length > 0
                ))
            );
          } else if (!isMenu || menuType == IMPORTATION_TYPES.OSCAR) {
            return (
              showIngredients ||
              (showLabels &&
                filterCertifications(certifications as string[]).length > 0)
            );
          }
        }
      );
      if (products?.length < 2 || filteredProducts.length < 1) {
        return null;
      }

      const getDeepProducts = (product: IProduct) =>
        products.filter(
          ({ main, idRecipe }) =>
            !main &&
            !!idRecipe &&
            !!product.idRecipe &&
            idRecipe.includes(product.idRecipe)
        );
      return <Section>
        <Title22 textAlign="left" style={styles.title}>
          {I18n.t("restaurantDetail.product.ingredients")}
        </Title22>
          <Title16>
            {filteredProducts
                .map((product, index, { length }) => {
              return (
                <>
                  <Title16>
                    {capitalize(trimEnd(product.label.toLowerCase(), '.'))}
                  </Title16>
                  {showLabels && !isEmpty(product.certifications) && (
                    product.certifications?.map((certification: any) => {
                      const labelName = (certificationsMapper as any)[certification];
                      if (!!labelName) {
                        return (
                          <Label style={{ marginLeft: Spacing.XS }}>
                            <Title14>{!!labelName ? I18n.t(`restaurantDetail.product.certifications.${labelName}`) : labelName}</Title14>
                          </Label>
                        )
                      } else {
                        return <></>
                      }
                    })
                  )}
                  {/* {showLabels && getDeepProducts(product).map((product, index, { length }) => (
                        <>
                          <Title16>
                            {index > 0 ? ` & ` : ` ${I18n.t(`restaurantDetail.product.certifications.with`)} `}{capitalize(trimEnd(product.label.toLowerCase(), '.'))}
                          </Title16>
                          {showLabels && !isEmpty(product.certifications) && (
                            product.certifications?.map((certification) => {
                              const labelName = (certificationsMapper as any)[certification];

                              if (!!labelName) {
                                return (
                                  <Label style={{ marginLeft: Spacing.XS }}>
                                    <Title14>{!!labelName ? I18n.t(`restaurantDetail.product.certifications.${labelName}`) : labelName}</Title14>
                                  </Label>
                                )
                              } else {
                                return <></>
                              }
                            })
                          )}
                        </>
                      ))
                  } */}
                  {index < length - 1 && <Title16>{', '}</Title16>}
                  {index === length - 1 && <Title16>{'.'}</Title16>}
                  <Text>{"\n"}</Text>
                </>
              )
            })}
          </Title16>
      </Section>;
    }

    const renderAllergens = () => {
      const filteredAllergens = allergens?.filter((allergen: any) => allergen !== 'ALLERGEN_FREE') ?? [];
      const allergensNotDiscolosed = filteredAllergens.includes('INFO_NOT_DISCLOSED');
      const allergensIntoIncomplete = filteredAllergens.includes('INFO_INCOMPLETE');
      if (filteredAllergens.length === 0) {
        return null;
      }

      return (
        <AllergensSection
          allergensNotDiscolosed={allergensNotDiscolosed}
          filteredAllergens={filteredAllergens}
          allergensIntoIncomplete={allergensIntoIncomplete}
        />
      );
    }

    const description = offerVM.getOfferDescription(item, menuType);
    const certificationsNotDiscolosed = certifications?.includes('INFO_NOT_DISCLOSED') || certifications?.includes('INFO_INCOMPLETE');
    const showLabels = !certificationsNotDiscolosed && productTransparencyPreferences?.showLabels && certifications && certifications.length > 0;
    const showIngredients = productTransparencyPreferences?.showIngredients && products && products.length > 0;
    const showAllergens = productTransparencyPreferences?.showAllergens && allergens && allergens.length > 0;
    const showDescription = !!productTransparencyPreferences?.showDescription;
    const showPhoto = !!productTransparencyPreferences?.showDescription;


    return (
      <ModalTemplate
        isOpen={isOpen}
        handleClose={handleClose}
        isMobile={isMobile}
        style={{ width: 414, padding: 0 }}
        isFullScreen
        paddingHorizontal={0}
        paddingVertical={0}
        isHideCloseButton
        closeOnClickOutside={true}
      >
        {showPhoto && !!inheritedImage && (
          <FastImage
            imagePath={inheritedImage}
            width={414}
            style={styles.previewImage}
          />
        )}
        <Section style={{ marginTop: Spacing.M }}>
          <Title22 textAlign="left" style={styles.title}>
            {capitalize(inheritedLabel)}
          </Title22>
          {showDescription && !!description && (
            <Title16>{description}.</Title16>
          )}
        </Section>
        {showLabels && renderLabels()}
        {(showIngredients || showLabels) && renderIngredients()}
        {showAllergens && renderAllergens()}
        {!isMenu && baking && baking.length > 0 ? <View style={{ backgroundColor: Colors.background1 }}>
          <View style={{ paddingVertical: Spacing.M }}>
            <Row center>
              <Title16 bold>{I18n.t("restaurantDetail.title")}</Title16>
            </Row>
          </View>
          <ScrollView horizontal={true}>
            {baking?.map((value) => (
                <View
                    style={{
                      paddingHorizontal: Spacing.S,
                      paddingBottom: Spacing.S,
                    }}
                >
                  <Tag
                      isSelected={chosenBaking === value}
                      onPress={() => handleChosenBaking(value)}
                      text={I18n.t(
                          `restaurantDetail.articleBaking.${getLabelArticleBaking(
                              value
                          )}`
                      )}
                  />
                </View>
            ))}
          </ScrollView>
          <Row center>
            <ProductQuantity
                isOverProductsLimit={isOverProductsLimit}
                sameProductPerOrder={sameProductPerOrder}
                onHandle={onControlQuantityWithBaking}
                productId={`${id}-in-modal`}
                quantityValue={quantityWithBaking}
                quantityOverall={quantityOverall}
                isBakingModal={true}
            />
          </Row>
          <View
              style={{
                marginTop: Spacing.M,
                paddingHorizontal: Spacing.M,
                paddingBottom: Spacing.M,
              }}
          >
            <Action
                testID={forwardTestID}
                onPress={onAddToCartWithBaking}
                disabled={isOverProductsLimit || !quantityWithBaking || !chosenBaking}
                brandTheme={brandTheme}
            >
              <AddActionText disabled={isOverProductsLimit || !quantityWithBaking || !chosenBaking} brandTheme={brandTheme}>
                {I18n.t("restaurantDetail.addToCart")}
              </AddActionText>
            </Action>
          </View>
        </View> : <View style={{ paddingBottom: Spacing.M }} />}
        <IconButton onPress={handleClose}>
          <CloseBlack />
        </IconButton>
      </ModalTemplate>
    );
  }
);

const styles = StyleSheet.create({
  title: {
    color: Colors.foodiBlack,
    fontFamily: "manrope-bold",
    overflow: "hidden",
    paddingBottom: Spacing.S
  },
  previewImage: {
    height: 238,
    width: 414,
    borderRadius: 0,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  accordeonArrow: {
    marginLeft: Spacing.XS
  },
  spacingBottom: { paddingBottom: Spacing.S },
  infoCertifications: {
    marginLeft: Spacing.XS,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  allergenNotice: {
    marginTop: Spacing.S,
  },
  allergenDetails: {
    display: "flex",
    flexDirection: 'row',
    marginRight: '16px'
  },
  toggleAllergens: {
    ...Platform.select({ web: {
        cursor: 'pointer',
      }}),
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1
  },
  toggleAllergensText: {
    textDecorationLine: 'underline'
  },
  icon: {
    marginLeft: 5,
  },
  allergensDetails: {
    height: 'auto',
    display: "flex",
    flexDirection: 'row' ,
    flexWrap: 'wrap'
  },
  label: {
    marginBottom: 10,
    marginRight: 10,
  },
  alertContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#f8f8f8',
    borderRadius: 8,
    padding: Spacing.M,
    marginVertical: Spacing.M,

    borderLeftColor: Colors.redError,
    ...Platform.select({
      web: {
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
      },
      default: {
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 4,
        elevation: 2
      }
    })
  },
  iconContainer: {
    backgroundColor: Colors.redError,
    borderRadius: 8,
    width: 36,
    height: 36,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: Spacing.M
  },
  warningSymbol: {
    color: 'white',
    fontSize: 24,
    fontWeight: 'bold'
  },
  textContainer: {
    flex: 1
  },
  alertTitle: {
    fontWeight: 'bold',
    color: Colors.redError,
    marginBottom: 4
  },
  alertText: {
    color: Colors.darkGray
  }
});
