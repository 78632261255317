import React, { useMemo, useState, useEffect } from "react";
import { View, Text, StyleSheet, SectionList, TouchableOpacity } from "react-native";
import { I18n } from "react-redux-i18n";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "@redux";
import {
  ModalTemplate,
  FastImage,
  ProductQuantity,
  RadioGroup,
  Button,
  CloseXButton,
  IImportationTypes,
  IMPORTATION_TYPES, Icon,
  AlertNotice,
} from "@atomic";
import { Title13, Title14, Title16, Title18, Title22 } from "@stylesheets";
import { useDevices } from "@hooks";
import { Colors, Spacing } from "@constants";
import { FormulaItem, IPosMenuElement, IProductTransparencyPreferences } from "@foodi/core";
import { IOfferItem, IProduct, OfferViewModel, OrderViewModel } from "@modules";
import { Container } from "@assets";
import { InformationOutline } from "@assets/icons/InformationOutline";
import { SmallDescription } from "@atomic/organisms/Cart/Cart.style";
import { Label, Row, Section } from "@atomic/molecules/ProductItemModal/ProductItemModal.style";
import { certificationsMapper } from "@utils/certificationsMapper";
import { capitalize, isEmpty, trimEnd } from "lodash";
import { allergensMapper } from "@utils/allergensMapper";
import moment from "moment";

export interface OfferFormulaItem extends IOfferItem {
  subProducts: any;
  steps: any;
  idFormulaTemplate: string;
}

interface IProps {
  isOpen?: boolean;
  onHandle: (formulaItem: any) => void;
  handleClose: () => void;
  selectedOfferId?: string;
  item: OfferFormulaItem;
  menuType?: IImportationTypes;
  productTransparencyPreferences: IProductTransparencyPreferences | null;
}

export const FormulasModal: React.FC<IProps> = React.memo(
  ({
    item,
    isOpen,
    onHandle,
    handleClose,
    selectedOfferId,
    menuType,
    productTransparencyPreferences
  }) => {
    const getDefaultFormulaCart = (length: number) => new Array(length).fill([]);
    const [isMobile] = useDevices();
    const [formulaTempCart, setFormulaTempCart] = useState(getDefaultFormulaCart(item.steps.length));
    const [isItemDetailOpen, setIsItemDetailOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState<any | null>();
    const [mountFlag, setMountFlag] = useState(false);

    const offer = useSelector((state: GlobalState) => state.offers.activeOrderableOffer);
    const isMenu = menuType !== undefined;
    const menuElements = offer?.menus ? offer.menus[0]?.elements : null;

    const userInfo = useSelector(
      (state: GlobalState) => ({
        idGuest: state.auth.userInfo?.idGuest,
        state: state.auth.userInfo?.state,
        validFrom: state.auth.userInfo?.validFrom,
        validTo: state.auth.userInfo?.validTo,
      })
    );
    const dispatch = useDispatch();
    const orderVM = new OrderViewModel(dispatch, userInfo.idGuest || '');
    const isBadgeExpired = orderVM.isBadgeExpired(userInfo.state, userInfo.validFrom ? moment(userInfo.validFrom) : null, userInfo.validTo ? moment(userInfo.validTo) : null) || false;

    useEffect(() => {
      if (item && !formulaTempCart.every((stepItems)=> !!stepItems.length) && !mountFlag) {
        const temp = [...formulaTempCart];
        for(let i = 0; i < item.steps.length; i++) {
          const stepProducts = filterSubProductsByStep(i);
          if(stepProducts.length === 1 && item.steps[i].maxProducts === 1 && stepProducts[0].quantityRemaining > 0) {
            temp[i] = [{
              id: stepProducts[0].id,
              baking: stepProducts[0].baking,
              container: stepProducts[0].container,
              idLocalArticle: stepProducts[0].localArticle.id,
              label: stepProducts[0].localArticle.label,
              quantity: 1,
            }];
          }
        }
        setMountFlag(true)
        setFormulaTempCart(temp);
      }
    }, [mountFlag]);

    const outOfStockSubItems = useSelector(
      (state: GlobalState) => state.offers?.outOfStockItemsFormula
    );

    const closeHandler = () => {
      setMountFlag(false);
      handleClose();
    }

    const handleItemDetailModal = (selectedProduct: any | null) => {
      setSelectedProduct(selectedProduct);
      setIsItemDetailOpen(!isItemDetailOpen);
    };

    const filterSubProductsByStep = (stepNumber: number) =>
    item.subProducts.filter((subProduct: FormulaItem):any => {
      const outOfStockItem = outOfStockSubItems?.some(
        (o) => o?.idOfferItemFormula === subProduct?.id
      );
      return subProduct.stepNumber === stepNumber && subProduct.quantityRemaining > 0 && !outOfStockItem
    });

    const sections = item?.steps?.map((step: any) => (
          { ...step, data: filterSubProductsByStep(step.order) }
        ));

    const handleHeaderComponent = useMemo(() => (
      <>
        <CloseXButton callback={closeHandler} closeXButtonStyle={styles.closeButton} />
        <FastImage imagePath={item.inheritedImage || ''} width={320} style={styles.image} />
        <View style={[styles.container, styles.header]}>
          <Title22 isBlack textAlign="start">
            {item.inheritedLabel}
            {!!item.container && (<> + <Container /></>)}
          </Title22>
          <View style={styles.descriptionContainer}>
            <Title16>
              {item.inheritedDescription}
            </Title16>
          </View>
        </View>
      </>
    ),[])

    const handleSectionHeader = (data: any) => {
      const { section: { name, maxProducts, order } } = data;
      return (
        <View style={[styles.container, styles.sectionHeader]}>
          {order > 0 && <View style={styles.stepSeparator} />}
          <Title18 isBold>{`${name} ${maxProducts === 1 ? '' : ` (${maxProducts} ${I18n.t("restaurantDetail.formula.max")})`}`}</Title18>
        </View>
      );
    };

    const handleFooterComponent = () => {
      const handleAddToCartPress = () => {
        const orderItem = {
          __typename: "OfferItem",
          id: item.id,
          quantity: 1,
          chosenBaking: null,
          labelWhenAdded: item.inheritedLabel,
          priceWhenAdded: {
            amount: item.inheritedPrice.amount,
            currency: item.inheritedPrice.currency,
          },
          containerLabelWhenAdded: item.container?.label ? item.container.label : null,
          containerPriceWhenAdded: {
            amount: item.container?.price?.amount ? item.container.price.amount : "",
            currency: item.inheritedPrice.currency,
          },
          products: formulaTempCart.flat(),
          offerItem: {
            __typename: "OfferItem",
            id: selectedOfferId || "id",
            inheritedFamily: item.inheritedFamily,
          },
        };
        onHandle(orderItem);
        closeHandler();
        setFormulaTempCart(getDefaultFormulaCart(item.steps.length));
      };

      return <View style={styles.footer}>
        {!!item?.container && (
          <SmallDescription marginLeft={10} marginBottom={12} fullWidth>
            {I18n.t("restaurantDetail.container.infoMessage")}
          </SmallDescription>
        )}
        <Button
          label={`${I18n.t("restaurantDetail.addToCart")} ${item.inheritedPrice.amount}€ ${!!item?.container?.price ? ` + ${item.container.price.amount}€*` : ''}`}
          styleLabel={styles.footerButtonLabel}
          styleButton={styles.footerButtonContainer}
          disabled={!formulaTempCart.every((stepItems)=> !!stepItems.length) || isBadgeExpired}
          onPress={handleAddToCartPress}
        />
      </View>;
    };

    const handleRenderItem = ({item, section: { maxProducts, order }}: {item: any, section: { maxProducts: number, order: number }}) => {

      const itemMenu : any  = isMenu ? item :  menuElements ? menuElements.find(
          (element) => element?.idElement === item?.localArticle?.article?.idElement
      ) : {} as IPosMenuElement;
      const allergens = itemMenu?.allergens;
      const certifications = itemMenu?.certifications || [];
      const products: IProduct[] = itemMenu?.products;

      const certificationsNotDiscolosed = certifications?.includes('INFO_NOT_DISCLOSED') || certifications?.includes('INFO_INCOMPLETE');
      const showLabels = !certificationsNotDiscolosed && productTransparencyPreferences?.showLabels && certifications && certifications.length > 0;
      const showIngredients = productTransparencyPreferences?.showIngredients && products && products.length > 0;
      const showAllergens = productTransparencyPreferences?.showAllergens && allergens && allergens.length > 0;
      const showPhoto = !!productTransparencyPreferences?.showPhoto;

      //@ts-ignore
      const canShowProductDetails = item?.localArticle?.article?.image_v2?.path;
      const canShowProductTransparencyDetails = ((showAllergens && allergens && allergens?.filter((allergen: string) => allergen !== 'ALLERGEN_FREE').length > 0) || (showLabels && certifications && certifications.length > 0) || (showIngredients && products && products?.length > 1)) && (showLabels || showIngredients || showAllergens);

      const getItemFromTempCart = () => {
        const itemIndex = formulaTempCart[order].findIndex((cartItem: any) => cartItem.id === item.id);
        return formulaTempCart[order]?.[itemIndex]
      }
      const handleCounterOnPress = (value: number) => {
        const temp = [...formulaTempCart];
        temp[order] = [
          ...temp[order].filter((cartItem: any) => cartItem.id !== item.id),
          {
            id: item.id,
            baking: item.baking,
            container: item.container,
            idLocalArticle: item.localArticle.id,
            quantity: value,
            label: item.localArticle.label,
          }
        ].filter((cartItem: any) => !!cartItem.quantity);

        setFormulaTempCart(temp);
      };
      const handleRadioOnPress = () => {
        const temp = [...formulaTempCart];
        temp[order] = [{
          id: item.id,
          baking: item.baking,
          container: item.container,
          idLocalArticle: item.localArticle.id,
          quantity: 1,
          label: item.localArticle.label,
        }];
        setFormulaTempCart(temp);
      };

      const quantity = getItemFromTempCart()?.quantity || 0;
      const productQuantityWidth = quantity === 0 ? {} : { width: 80 };
      const isOverProductsLimit =
        maxProducts > 1 ? maxProducts === formulaTempCart[order].reduce(
          (acc: any, curr: any) => acc + curr.quantity, 0) : false;

      return (
        <View style={[styles.container, styles.item]}>
          <View style={styles.labelContainer} >
            {maxProducts === 1 ?
              <RadioGroup
                radioButtonsProps={[item]}
                value={formulaTempCart[order]?.[0]?.id === item.id ? item.id : null}
                onPress={handleRadioOnPress}
                style={styles.productQuantityContainer}
                /> :
              <ProductQuantity
                isOverProductsLimit={isOverProductsLimit}
                onHandle={handleCounterOnPress}
                productId={item.id}
                quantityValue={quantity}
                quantityOverall={item.quantityOverall}
                disableDefaultStyle
                style={[styles.productQuantityContainer, productQuantityWidth]}
              />
            }
            <View style={{ flex: 1}}>
              <View style={{flex: 1}}>
                <Title16
                  numberOfLines={10}
                  ellipsizeMode='tail'
                >
                  {item.localArticle.label}
                </Title16>
              </View>
            </View>
          </View>
          {(canShowProductDetails || canShowProductTransparencyDetails) &&
            <TouchableOpacity onPress={() => handleItemDetailModal(item)} style={[styles.information]}>
              <InformationOutline />
            </TouchableOpacity>
          }
        </View>
      )
    };

    const modalHeightStyle = isItemDetailOpen ? { minHeight: 672 } : {};

    return (
      <ModalTemplate
        isOpen={isOpen}
        handleClose={closeHandler}
        isMobile={isMobile}
        style={[styles.modal]}
        isFullScreen
        isHideCloseButton
        closeOnClickOutside={true}
        paddingVertical={0}
        paddingHorizontal={0}
      >
        {isItemDetailOpen && selectedProduct && (
          <ProductDetailsModal
              handleItemDetailModal={handleItemDetailModal}
              selectedProduct={selectedProduct}
              menuType={menuType}
              menuElements={menuElements}
              productTransparencyPreferences={productTransparencyPreferences}
          />
        )}

        {!isItemDetailOpen && (
            <SectionList
              keyExtractor={(_, index) => 'formulaItem' + index}
              sections={sections}
              ListHeaderComponent={handleHeaderComponent}
              ListFooterComponent={handleFooterComponent}
              renderSectionHeader={handleSectionHeader}
              stickySectionHeadersEnabled={false}
              initialNumToRender={100}
              renderItem={handleRenderItem}
            />
        )}
      </ModalTemplate>
    );
  }
);

interface IPropsProductDetailsModal {
  handleItemDetailModal: (selectedProduct: any | null) => void;
  selectedProduct: OfferFormulaItem;
  menuType?: IImportationTypes;
  menuElements: IPosMenuElement[] | null;
  productTransparencyPreferences: IProductTransparencyPreferences | null;
}
const ProductDetailsModal: React.FC<IPropsProductDetailsModal> = ({
    handleItemDetailModal,
    selectedProduct,
    menuType,
    menuElements,
    productTransparencyPreferences
  }) => {

  const isMenu = menuType !== undefined;
  const itemMenu : any  = isMenu ? selectedProduct :  menuElements ? menuElements.find(
      (element) => element?.idElement === selectedProduct?.localArticle?.article?.idElement
  ) : {} as IPosMenuElement;
  const allergens = itemMenu?.allergens;
  const certifications = itemMenu?.certifications || [];
  const products: IProduct[] = itemMenu?.products;

  const certificationsNotDiscolosed = certifications?.includes('INFO_NOT_DISCLOSED') || certifications?.includes('INFO_INCOMPLETE');
  const showLabels = !certificationsNotDiscolosed && productTransparencyPreferences?.showLabels && certifications && certifications.length > 0;
  const showIngredients = productTransparencyPreferences?.showIngredients && products && products.length > 0;
  const showAllergens = productTransparencyPreferences?.showAllergens && allergens && allergens.length > 0;
  const showPhoto = !!productTransparencyPreferences?.showPhoto;

  const renderLabels = () => {
    const certificationsNotDiscolosed = certifications?.includes('INFO_NOT_DISCLOSED') || certifications?.includes('INFO_INCOMPLETE');
    // IF we hide ingredients we must show Labels in the Label sections
    if (certificationsNotDiscolosed || certifications?.length === 0 || products?.length && products.length > 1) {
      return null;
    }

    return (
        <Section>
          <Title22 textAlign="left" style={styles.title}>
            {I18n.t("restaurantDetail.product.label")}
          </Title22>
          <Row wrap>
            {certifications?.map((label: any) => {
                  const labelName = (certificationsMapper as any)[label];

                  return <Label>
                    <Title14>{!!labelName ? I18n.t(`restaurantDetail.product.certifications.${labelName}`) : labelName}</Title14>
                  </Label>
                }
            )}
          </Row>
        </Section>
    );
  }

  const renderIngredients = () => {
    if (!products) {
      return null;
    }

    const filteredProducts = products.filter(
        ({ idRecipe: mainIdRecipe, main, certifications = [] }) =>
            menuType != IMPORTATION_TYPES.WINAPRO || (main
                &&
                (showIngredients ||
                    certifications.length > 0 ||
                    !!products.some(
                        ({ idRecipe, main }) =>
                            !main &&
                            !!idRecipe &&
                            !!mainIdRecipe &&
                            idRecipe.includes(mainIdRecipe)
                    )))
    );
    if (products?.length < 2 || filteredProducts.length < 1) {
      return null;
    }

    const getDeepProducts = (product: IProduct) =>
        products.filter(
            ({ main, idRecipe }) =>
                !main &&
                !!idRecipe &&
                !!product.idRecipe &&
                idRecipe.includes(product.idRecipe)
        );
    return <Section>
      <Title22 textAlign="left" style={styles.title}>
        {I18n.t("restaurantDetail.product.ingredients")}
      </Title22>
      <Row wrap>
        <Title16>
          {filteredProducts
              .map((product, index, { length }) => {
                return (
                    <>
                      <Title16>
                        {capitalize(trimEnd(product.label.toLowerCase(), '.'))}
                      </Title16>
                      {showLabels && !isEmpty(product.certifications) && (
                          product.certifications?.map((certification: any) => {
                            const labelName = (certificationsMapper as any)[certification];
                            if (!!labelName) {
                              return (
                                  <Label style={{ marginLeft: Spacing.XS }}>
                                    <Title14>{!!labelName ? I18n.t(`restaurantDetail.product.certifications.${labelName}`) : labelName}</Title14>
                                  </Label>
                              )
                            } else {
                              return <></>
                            }
                          })
                      )}
                      {showLabels && getDeepProducts(product).map((product, index, { length }) => (
                          <>
                            <Title16>
                              {index > 0 ? ` & ` : ` ${I18n.t(`restaurantDetail.product.certifications.with`)} `}{capitalize(trimEnd(product.label.toLowerCase(), '.'))}
                            </Title16>
                            {showLabels && !isEmpty(product.certifications) && (
                                product.certifications?.map((certification) => {
                                  const labelName = (certificationsMapper as any)[certification];

                                  if (!!labelName) {
                                    return (
                                        <Label style={{ marginLeft: Spacing.XS }}>
                                          <Title14>{!!labelName ? I18n.t(`restaurantDetail.product.certifications.${labelName}`) : labelName}</Title14>
                                        </Label>
                                    )
                                  } else {
                                    return <></>
                                  }
                                })
                            )}
                          </>
                      ))
                      }
                      {index < length - 1 && <Title16>{', '}</Title16>}
                      {index === length - 1 && <Title16>{'.'}</Title16>}
                    </>
                )
              })}
        </Title16>
      </Row>
    </Section>;
  }

  const renderAllergens = () => {
    const filteredAllergens = allergens?.filter((allergen: any) => allergen !== 'ALLERGEN_FREE') ?? [];
    const allergensNotDiscolosed = filteredAllergens.includes('INFO_NOT_DISCLOSED');
    const allergensIntoIncomplete = filteredAllergens.includes('INFO_INCOMPLETE');
    if (filteredAllergens.length === 0) {
      return null;
    }
    const allergensRows = filteredAllergens?.map(
      (allergen: any, index: number, array: any) => {
        const { ALLERGEN_FREE, INFO_INCOMPLETE } = allergensMapper;
        const allergenName = (allergensMapper as any)[allergen];
        const isLastItem = index === array.length - 1;
        // Don't render INFO_INCOMPLETE allergen type
        if ([INFO_INCOMPLETE].includes(allergenName)) {
          return null;
        }
        if (!allergenName) {
          return `${allergen}${isLastItem ? "" : ", "}`;
        } else {
          return (
            <View style={styles.allergenDetails}>
              {![ALLERGEN_FREE, INFO_INCOMPLETE].includes(
                allergenName
              ) && (
                <Icon
                  name={allergenName}
                  size={16}
                  style={{ marginRight: Spacing.XS }}
                />
              )}
              <Title16>
                {I18n.t(
                  `restaurantDetail.product.allergensList.${allergenName}`
                )}
              </Title16>
            </View>
          );
        }
      }
    )

    return (
      <Section>
        <Title22 textAlign="left" style={styles.title}>
          {I18n.t("restaurantDetail.product.allergens")}
        </Title22>
        {allergensNotDiscolosed ? (
          <AlertNotice
            visible={true}
            type="infoNotDisclosed"
            style={{ marginVertical: "15px" }}
          />
        ) : (
          <View style={styles.allergensDetails}>{allergensRows}</View>
        )}
        {allergensIntoIncomplete && (
          <AlertNotice
            visible={true}
            type="infoIncomplete"
            style={{ marginVertical: "15px" }}
          />
        )}
      </Section>
    );
  }

  // @ts-ignore
  const imagePath = selectedProduct.localArticle?.article?.image_v2?.path;
  // @ts-ignore
  const articleLabel = selectedProduct?.localArticle?.article?.label;
  // @ts-ignore
  const articleDescription = selectedProduct?.localArticle?.article?.description;

  return (
      <>
        <CloseXButton callback={() => handleItemDetailModal(null)} closeXButtonStyle={styles.closeButton} />
        {imagePath &&
            <FastImage imagePath={imagePath} width={320} style={styles.image} />
        }
        <View style={[styles.container, styles.header]}>
          <Title22 isBlack textAlign="start">
            {articleLabel}
          </Title22>
          <View style={styles.descriptionContainer}>
            <Title16>
              {articleDescription}
            </Title16>
          </View>
        </View>
        {showLabels && renderLabels()}
        {(showIngredients || showLabels) && renderIngredients()}
        {showAllergens && renderAllergens()}
      </>
  );
}

const styles = StyleSheet.create({
  modal: {
    width: '25vw',
    minWidth: 350
  },
  labelContainer: {
    flexDirection: 'row',
    flex: 1,
    paddingRight: '10px'
  },
  image: {
    height: 250,
    objectFit: 'cover',
    width:'100%',
    borderRadius: 0,
  },
  container: {
    paddingHorizontal: 18,
  },
  closeButton:{
    backgroundColor: Colors.disabledBackground,
    alignItems: 'center',
    justifyContent: 'center',
    height:24,
    width:24,
    borderRadius: 12,
    top: 15,
    right: 15,
    zIndex: 99,
  },
  header: {
    paddingTop: 20,
  },
  descriptionContainer: {
    marginTop: 5
  },
  sectionHeader: {
    paddingTop: 10,
    paddingBottom: 16,
  },
  item: {
    flexDirection:'row',
    textAlign:'left',
    marginVertical: 7,
    justifyContent: 'space-between'
  },
  productQuantityContainer: {
    paddingRight: 10,
    flexDirection: 'row-reverse',
    justifyContent: "space-between",
  },
  footer: {
    backgroundColor: Colors.background1,
    marginTop: 15,
    paddingVertical: 20,
    alignItems: 'center',
  },
  footerButtonLabel: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  footerButtonContainer: {
    marginHorizontal: 10
  },
  information: {
    alignSelf: 'center',
  },
  stepSeparator: {
    borderTopColor: Colors.darkGrey,
    borderTopWidth: 1,
    marginBottom: 10,
  },
  title: {
    color: Colors.foodiBlack,
    fontFamily: "manrope-bold",
    overflow: "hidden",
    paddingBottom: Spacing.S
  },
  allergenNotice: {
    marginTop: Spacing.S,
  },
  allergenDetails: {
    display: "flex",
    flexDirection: 'row',
    marginRight: '16px'
  },
  allergensDetails: { height: 20, display: "flex", flexDirection: 'row' },
})
